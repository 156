import { createIcon, Icon } from '@chakra-ui/react'

export const EmailIcon = createIcon({
  d: 'M20.739 4.15H3.26A2.828 2.828 0 00.436 6.975v10.05a2.828 2.828 0 002.825 2.825H20.74a2.828 2.828 0 002.825-2.825V6.975a2.828 2.828 0 00-2.825-2.825m-1.38 1.843L12 10.733l-7.358-4.74h14.716m1.38 12.015H3.262a.983.983 0 01-.982-.982V7.027l9.152 5.885c.013.008.026.013.039.02a1.061 1.061 0 00.259.107l.023.005c.082.02.164.031.247.031h.002c.083 0 .166-.012.248-.031l.023-.005a1.068 1.068 0 00.26-.107c.012-.007.025-.012.037-.02l9.152-5.885v9.998a.984.984 0 01-.982.983',
  viewBox: '0 0 24 24',
  displayName: 'Email Icon'
})

export const PhoneIcon = createIcon({
  d: 'M23.236 17.933c-.855-1.616-3.824-3.368-3.954-3.445-.382-.217-.78-.332-1.152-.332-.554 0-1.007.254-1.282.715-.434.52-.973 1.127-1.103 1.22-1.012.687-1.804.609-2.68-.267l-4.89-4.89c-.87-.87-.95-1.672-.269-2.678.096-.132.703-.67 1.222-1.105.331-.198.559-.49.658-.85.133-.478.035-1.04-.277-1.588-.074-.126-1.827-3.095-3.442-3.95A2.106 2.106 0 005.085.52a2.09 2.09 0 00-1.485.615l-1.08 1.08C.81 3.923.191 5.86.68 7.97c.407 1.76 1.593 3.631 3.525 5.564l6.26 6.26c2.446 2.446 4.778 3.686 6.932 3.686 1.584 0 3.06-.673 4.387-2l1.08-1.08a2.09 2.09 0 00.372-2.467',
  viewBox: '0 0 24 24',
  displayName: 'Phone Icon'
})

export const TelegramIcon = createIcon({
  d: 'M17.443 8.436c.73-.73 1.454-2.432-1.586-.365a523.28 523.28 0 01-8.58 5.78 3.57 3.57 0 01-2.798.061C2.654 13.365.525 12.635.525 12.635s-1.455-.914 1.035-1.887c0 0 10.525-4.319 14.175-5.84 1.399-.609 6.144-2.555 6.144-2.555s2.19-.852 2.008 1.217c-.061.851-.548 3.834-1.034 7.057-.731 4.562-1.522 9.552-1.522 9.552s-.121 1.398-1.155 1.642a5.007 5.007 0 01-3.041-1.095c-.243-.182-4.562-2.92-6.145-4.258a1.153 1.153 0 01.06-1.948 223.838 223.838 0 006.393-6.084z',
  viewBox: '0 0 24 24',
  displayName: 'Telegram Icon'
})

export const GithubIcon = createIcon({
  d: 'M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12',
  viewBox: '0 0 24 24',
  displayName: 'Github Icon'
})

/** Icon factory */
export const getIcon = ({ name, props }) => {
  switch (name) {
    case 'email':
      return <EmailIcon {...props} />
    case 'phone':
      return <PhoneIcon {...props} />
    case 'telegram':
      return <TelegramIcon {...props} />
    case 'github':
      return <GithubIcon {...props} />
    default:
      return <Icon {...props} />
  }
}

/** Logo icon */
const ContactsBookIcon = createIcon({
  d: 'M45.106 5.2h-2.771V16.284h2.771a2.87 2.87 0 002.867-2.867V8.066A2.87 2.87 0 0045.106 5.2M47.973 27.8v-5.352a2.87 2.87 0 00-2.867-2.866h-2.771v11.085h2.771a2.87 2.87 0 002.867-2.867M42.335 44.69c0 .12-.005.24-.01.359h2.781a2.87 2.87 0 002.867-2.867v-5.351a2.87 2.87 0 00-2.867-2.867h-2.771V44.69M37.523 2.595a2.807 2.807 0 00-.922-.153H10.943v45.116H36.6a2.866 2.866 0 002.867-2.867V33.964v-3.297-11.085-3.297V5.309c0-.038 0-.071-.005-.11a2.858 2.858 0 00-1.94-2.604M24.173 24.17h-7.195a1.91 1.91 0 110-3.823h7.19a1.91 1.91 0 011.912 1.911c0 1.056-.85 1.912-1.907 1.912m7.191-8.706H16.978a1.91 1.91 0 110-3.823h14.386a1.91 1.91 0 110 3.823M3.971 2.595A2.862 2.862 0 002.027 5.31v39.382a2.87 2.87 0 002.867 2.867h2.704V2.595v-.153H4.894c-.325 0-.636.053-.923.153',
  viewBox: '0 0 50 50',
  displayName: 'Contacts Book Icon'
})

export default ContactsBookIcon
