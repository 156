import { createIcon, Icon } from '@chakra-ui/react'

export const USTVGOIPTVIcon = createIcon({
  path: (
    <g>
      <path
        d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30 30-13.432 30-30S48.568 2 32 2m11.644 32.952c0 3.084-.479 5.486-1.434 7.205-1.783 3.149-5.182 4.725-10.201 4.725-5.018 0-8.424-1.575-10.219-4.725-.957-1.719-1.434-4.121-1.434-7.205V17.118h6.16v17.82c0 1.993.236 3.448.707 4.366.732 1.626 2.328 2.439 4.785 2.439 2.445 0 4.035-.813 4.768-2.439.471-.918.705-2.373.705-4.366v-17.82h6.162v17.834z"
        fill="#e53935"
      />
      <path
        d="M20.252 17.073c-.073 5.767.023 11.567.048 17.347-.015 2.53.024 5.174 1.249 7.463 1.329 2.826 4.333 4.393 7.302 4.88 2.163.254 4.37.191 6.534-.038 3.002-.5 5.955-2.247 7.205-5.123 1.497-3.29 1.07-7.014 1.208-10.523.019-4.627.006-9.254.01-13.88-.577-.458-1.616-.074-2.37-.2-1.353.049-2.752-.1-4.076.074-.128 6.614-.066 13.26-.12 19.887 0 1.231-.55 2.495-1.374 3.415-.93.964-2.29 1.19-3.558 1.096-1.296.04-2.693.083-3.77-.763-1.596-1.102-1.817-3.218-1.814-4.991-.138-6.172-.102-12.347-.16-18.52-.579-.455-1.616-.073-2.37-.199-1.307.05-2.666-.098-3.944.075z"
        fill="#fff"
      />
    </g>
  ),
  viewBox: '0 0 64 64',
  displayName: 'USTVGO IPTV Icon'
})

export const SMSPollIcon = createIcon({
  path: (
    <g>
      <circle r="60" cy="60" cx="60" fill="#3d4965" />
      <path
        d="M19.389 36.921v25.075a17.189 17.189 0 0111.459-4.405 17.185 17.185 0 0111.46 4.402V36.92H19.388M49.03 26.504v35.492a17.189 17.189 0 0111.46-4.405 17.189 17.189 0 0111.459 4.402v-35.49H49.03M78.751 44.696v17.3a17.189 17.189 0 0111.46-4.405 17.185 17.185 0 0111.458 4.402V44.696H78.751"
        fill="#91d2ff"
      />
      <path
        d="M22.403 86.448c0-.593.2-1.09.603-1.492.423-.424.92-.635 1.492-.635.466 0 .868.127 1.207.38 1.63 1.123 3.408 1.683 5.334 1.683 1.122 0 2.01-.222 2.667-.666.677-.466 1.016-1.037 1.016-1.715 0-.677-.328-1.206-.985-1.587-.656-.381-1.756-.752-3.302-1.112-1.63-.38-2.973-.762-4.032-1.143-1.143-.423-2.074-1.08-2.794-1.968-.72-.89-1.08-2.01-1.08-3.366 0-1.841.752-3.365 2.255-4.572 1.524-1.206 3.556-1.81 6.096-1.81 2.392 0 4.583.583 6.572 1.747.699.38 1.048.974 1.048 1.778 0 .571-.212 1.069-.635 1.492a1.953 1.953 0 01-1.429.603c-.423 0-.783-.105-1.08-.317-1.608-.91-3.206-1.365-4.794-1.365-1.121 0-1.979.19-2.571.571-.572.381-.858.91-.858 1.588 0 .614.297 1.08.89 1.397.592.296 1.703.645 3.333 1.047 1.503.318 2.837.71 4 1.175 2.625.974 3.938 2.762 3.938 5.366 0 2.074-.741 3.725-2.223 4.953-1.481 1.228-3.566 1.841-6.255 1.841-2.963 0-5.45-.709-7.46-2.127-.636-.402-.953-.984-.953-1.746"
        fill="#fff"
      />
      <path
        d="M81.765 86.448c0-.593.2-1.09.603-1.492.423-.424.92-.635 1.492-.635.466 0 .868.127 1.207.38 1.63 1.123 3.408 1.683 5.334 1.683 1.122 0 2.01-.222 2.667-.666.677-.466 1.016-1.037 1.016-1.715 0-.677-.328-1.206-.984-1.587-.657-.381-1.757-.752-3.302-1.112-1.63-.38-2.974-.762-4.033-1.143-1.143-.423-2.074-1.08-2.794-1.968-.72-.89-1.08-2.01-1.08-3.366 0-1.841.752-3.365 2.255-4.572 1.524-1.206 3.556-1.81 6.096-1.81 2.392 0 4.583.583 6.572 1.747.699.38 1.048.974 1.048 1.778 0 .571-.212 1.069-.635 1.492a1.953 1.953 0 01-1.429.603c-.423 0-.783-.105-1.08-.317-1.608-.91-3.206-1.365-4.793-1.365-1.122 0-1.98.19-2.572.571-.572.381-.857.91-.857 1.588 0 .614.296 1.08.889 1.397.592.296 1.703.645 3.333 1.047 1.503.318 2.837.71 4 1.175 2.625.974 3.938 2.762 3.938 5.366 0 2.074-.741 3.725-2.223 4.953-1.481 1.228-3.566 1.841-6.254 1.841-2.964 0-5.45-.709-7.462-2.127-.635-.402-.952-.984-.952-1.746"
        fill="#fff"
      />
      <path
        d="M67.427 87.94V74.732L61.68 89.37c-.254.55-.656.825-1.206.825-.55 0-.953-.275-1.207-.825L53.52 74.732V87.94c0 .614-.222 1.143-.666 1.588a2.065 2.065 0 01-1.556.666 2.205 2.205 0 01-1.62-.666 2.168 2.168 0 01-.666-1.588V72.256c0-1.016.36-1.884 1.08-2.604a3.548 3.548 0 012.603-1.08c.783 0 1.482.223 2.095.667.635.424 1.09.995 1.366 1.715l4.318 11.049 4.35-11.05a3.529 3.529 0 011.333-1.714 3.575 3.575 0 012.095-.666c1.038 0 1.916.36 2.636 1.08.72.719 1.08 1.587 1.08 2.603V87.94c0 .614-.223 1.143-.668 1.588a2.205 2.205 0 01-1.619.666 2.168 2.168 0 01-1.587-.666 2.168 2.168 0 01-.667-1.588"
        fill="#fff"
      />
    </g>
  ),
  viewBox: '0 0 120 120',
  displayName: 'SMS Poll Icon'
})

export const Parser2GISIcon = createIcon({
  path: (
    <g>
      <path
        d="M23.245 6.075v36.167c9.669 1.166 14.753 1.374 17.718-.014 2.138-1.806 4.931-4.956 5.784-6.784 3.477-7.49 2.881-12.674 1.355-18.162-1.128-2.701-1.848-4.082-2.472-4.998a19.348 19.348 0 00-1.78-2.27c-.976-1.09-1.876-1.88-2.863-2.715-.35-.296-.694-.587-1.118-.9-.236-.175-.556-.172-.857-.221-3.691-.607-8.466-.103-15.767-.103z"
        fill="#dbcf86"
      />
      <path
        d="M25.557 1.806a22.278 22.278 0 00-2.567.117C12.28 3.048 3.814 10.681 1.934 20.913c-.246 1.337-.308 2.5-.248 4.627.045 1.562.148 2.95.23 3.081.109.177.376.084 1.013-.354 2.99-2.057 6.969-2.615 10.4-1.458 2.083.702 3.175 1.385 4.802 3.007 2.348 2.339 3.468 5.053 3.468 8.4 0 2.775-.738 4.993-2.427 7.286l-.855 1.162.629.259c.406.13 1.056.32 1.674.429.588.103 1.23.153 1.657.183.213.048 1.956.057 3.873.02 2.71-.053 3.81-.154 4.94-.455 2.25-.6 3.8-1.158 5.303-1.908 1.657-.828 4.573-2.73 4.573-2.982 0-.11-3.355-.182-8.522-.182h-8.52V39.56l.002-2.469 3.421-2.905c4.318-3.665 8.95-8.226 10.525-10.36 2.771-3.76 3.607-7.374 2.26-9.776-1.367-2.436-5.526-2.758-7.613-.59-1.107 1.15-1.457 2.26-1.574 4.991l-.098 2.276h-3.052c-3.525 0-3.206.217-3.416-2.333-.164-1.993.305-4.29 1.288-6.306 1.326-2.72 3.91-4.744 7.165-5.61 1.343-.357 5.41-.488 6.487-.208l.25.065.331.086-.666-.496c-.97-.723-3.76-2.117-5.434-2.718-2.462-.883-5.435-1.376-8.243-1.4zM48.025 17.3c-.057.011-.088.154-.088.442 0 1.857-1.565 5.392-3.55 8.02-1.476 1.951-4.816 5.23-7.779 7.635-1.172.95-2.213 1.8-2.314 1.888-.101.088 2.66.16 6.134.16h6.32l.514-1.09c2.279-4.816 2.793-10.25 1.448-15.288-.311-1.166-.56-1.79-.685-1.767z"
        fill="#507138"
      />
      <path
        d="M9.452 28.86c-1.297 0-1.796.43-1.796 1.55 0 .859-.515 1.548-1.155 1.548-.21 0-.679-.261-1.043-.58-.912-.802-1.431-.749-2.41.249-.966.984-1.04 1.583-.306 2.455.525.625.661 1.431.302 1.791-.114.113-.669.28-1.234.369-.594.094-1.13.302-1.27.494-.277.377-.324 2.347-.068 2.752.093.147.631.34 1.195.43.563.089 1.15.288 1.304.441.435.435.333 1.25-.23 1.838-.792.827-.688 1.553.36 2.518.476.44 1.04.8 1.252.8.211 0 .682-.262 1.046-.581.364-.32.846-.581 1.072-.581.649 0 1.184.676 1.188 1.5.003.818.118 1.051.674 1.367.214.12.802.222 1.308.225 1.187.008 1.694-.454 1.694-1.543 0-.868.516-1.55 1.174-1.55.219 0 .737.273 1.15.605.557.447.875.574 1.22.488.542-.136 2.072-1.721 2.072-2.147 0-.158-.261-.585-.58-.95-.632-.719-.774-1.661-.309-2.047.15-.125.726-.298 1.28-.386.554-.088 1.084-.28 1.177-.427.257-.405.208-2.375-.067-2.752-.14-.193-.678-.4-1.28-.496-1.541-.243-1.84-1.094-.802-2.278.32-.364.581-.818.581-1.01 0-.525-1.693-2.156-2.239-2.156-.252 0-.756.262-1.12.581-1.182 1.039-2.257.537-2.257-1.054 0-1.104-.462-1.463-1.883-1.463zm.005 5.402c.65-.012 1.295.11 1.834.385 2.834 1.446 2.87 5.449.062 6.93-2.496 1.319-5.363-.224-5.757-3.098-.122-.894.369-2.344 1.05-3.097.633-.7 1.728-1.098 2.811-1.12z"
        fill="#707070"
      />
    </g>
  ),
  viewBox: '0 0 50 50',
  displayName: 'Parser 2GIS Icon'
})

export const QDCConverterIcon = createIcon({
  path: (
    <g>
      <circle r="11.61" cy="12.065" cx="12.044" fill="#333" />
      <path
        d="M5.247 16.722c.177.353.536.572.983.572H17.86c.445 0 .803-.175.983-.572.224-.35.224-.791 0-1.144l-5.816-9.903c-.177-.351-.534-.571-.982-.571-.402 0-.76.22-.983.57l-5.814 9.904c-.225.35-.225.795 0 1.144z"
        fill="#379ee7"
      />
    </g>
  ),
  viewBox: '0 0 24 24',
  displayName: 'GDC Converter Icon'
})

/** Icon factory */
export const getIcon = ({ name, props }) => {
  switch (name) {
    case 'project-ustvgo':
      return <USTVGOIPTVIcon {...props} />
    case 'project-smspoll':
      return <SMSPollIcon {...props} />
    case 'project-parser2gis':
      return <Parser2GISIcon {...props} />
    case 'project-qdcconverter':
      return <QDCConverterIcon {...props} />
    default:
      return <Icon {...props} />
  }
}

/** Logo icon */
const CodeIcon = createIcon({
  d: 'M27.523 7.16a.829.829 0 00-.703.598l-9.48 32.654a.834.834 0 00.568 1.035l4.238 1.229a.83.83 0 001.034-.569l9.48-32.656a.834.834 0 00-.568-1.035l-4.238-1.229a.846.846 0 00-.33-.027zm11.608 7.35a.839.839 0 00-.584.26l-3.022 3.222c-.32.34-.3.881.055 1.194l6.293 5.543-6.293 5.535a.828.828 0 00-.055 1.195l3.022 3.223c.312.34.84.353 1.18.04l10.007-9.382a.827.827 0 000-1.215l-10.007-9.39a.833.833 0 00-.596-.225zm-28.256.207a.838.838 0 00-.602.228L.266 24.32a.827.827 0 000 1.215l10.007 9.383c.34.32.868.307 1.18-.033l3.022-3.223c.32-.34.3-.883-.055-1.195l-6.293-5.535 6.293-5.536a.826.826 0 00.055-1.193l-3.022-3.223a.827.827 0 00-.578-.263z',
  viewBox: '0 0 50 50',
  displayName: 'Code Icon'
})

export default CodeIcon
