import { createIcon } from '@chakra-ui/react'

/** Logo icon */
const MortarboardHatIcon = createIcon({
  d: 'M38.076 23.508l-12.058 4.495a2.885 2.885 0 01-1.008.173c-.336 0-.672-.049-1.008-.173l-12.058-4.495-.38 7.85c-.169 3.502 6.031 6.357 13.446 6.357s13.614-2.855 13.446-6.357zm11.135-8.047c0-.346-.188-.647-.465-.77L25.212 5.946c-.084-.025-.148-.025-.212-.025s-.128 0-.212.025L1.254 14.69c-.272.124-.465.42-.465.77 0 .347.188.648.465.772l7.004 2.583c-1.304 2.159-1.931 5.29-2.06 8.196-.8.548-1.363 1.566-1.363 2.756 0 1.117.484 2.085 1.22 2.658l-1.22 10.759c-.02.222.045.45.168.622a.639.639 0 00.504.272h4.036c.188 0 .38-.099.504-.272a.91.91 0 00.168-.622L8.995 32.43c.736-.573 1.22-1.542 1.22-2.658 0-1.166-.524-2.159-1.324-2.707.168-3.354 1.008-6.086 2.208-7.203l13.698 5.118c.084.025.149.025.213.025s.128 0 .212-.025l23.534-8.743c.267-.129.455-.425.455-.776z',
  viewBox: '0 0 50 50',
  displayName: 'Mortarboard Hat Icon'
})

export default MortarboardHatIcon
