import { createIcon } from '@chakra-ui/react'

/** Logo icon */
const PythonIcon = createIcon({
  d: 'M12 .035c-2.287 0-2.353.005-2.903.221-.86.34-1.644 1.102-1.972 1.92-.227.566-.24.663-.262 1.99L6.84 5.442h2.655c2.924 0 2.862-.006 3.073.29.217.305.13.792-.18 1.002-.126.085-.311.09-4.813.114-4.932.026-4.732.017-5.314.244-.868.339-1.634 1.09-1.98 1.941-.239.588-.243.64-.243 2.963s.004 2.375.243 2.962c.437 1.076 1.52 1.947 2.654 2.137.132.022.75.042 1.375.045l1.135.006v-1.747c0-1.044.02-1.86.05-2.025.176-.968 1.062-1.854 2.03-2.03.185-.034 1.676-.05 4.45-.05 4.7 0 4.456.017 4.825-.351.368-.369.354-.187.339-4.36l-.014-3.693-.155-.444a3.523 3.523 0 00-.884-1.412 3.387 3.387 0 00-1.122-.755c-.59-.24-.64-.244-2.965-.244m-1.625 1.901c.535 0 .97.425.97.948a.959.959 0 01-.97.948.959.959 0 01-.97-.948c0-.523.434-.948.97-.948m8.185 4.901l-.017 1.913c-.015 1.838-.02 1.922-.12 2.189a2.655 2.655 0 01-1.593 1.614l-.312.122-4.401.023-4.4.023-.223.11c-.122.06-.285.186-.362.28-.295.356-.287.233-.27 4.298.017 3.975.005 3.763.263 4.407.248.617.858 1.318 1.449 1.664.33.194.856.392 1.197.451.132.023 1.198.038 2.37.034l2.13-.008.444-.155a3.466 3.466 0 001.44-.915c.398-.42.645-.787.84-1.348 0 0 .044-.101.127-.438.054-.285.018-1.275.018-1.275l-.013-1.263-2.623-.013c-2.351-.011-2.672-.009-2.814-.076-.465-.22-.518-.898-.094-1.2l.147-.105 4.681-.023 4.681-.024.445-.155a3.467 3.467 0 001.439-.915 3.47 3.47 0 00.824-1.362l.148-.424v-4.54l-.155-.445a4.641 4.641 0 00-.36-.773c-.364-.583-1.023-1.145-1.623-1.386-.57-.228-.665-.241-1.99-.263l-1.273-.022m-4.915 13.36c.535 0 .97.424.97.947a.959.959 0 01-.97.948.959.959 0 01-.97-.948c0-.523.434-.948.97-.948',
  viewBox: '0 0 24 24',
  displayName: 'Python Icon'
})

export default PythonIcon
