import { createIcon } from '@chakra-ui/react'

/** Logo icon */
const WrenchAndHammerIcon = createIcon({
  d: 'M39 3.713c-.885 0-1.775.266-2.543.808L34.422 5.96a7.739 7.739 0 00-3.264 5.908l-.24 4.502L9.529 37.758a4.453 4.453 0 000 6.299c.871.87 2.01 1.306 3.15 1.306 1.14 0 2.28-.434 3.151-1.304l21.389-21.39 4.502-.24a7.736 7.736 0 005.906-3.263l1.44-2.035a4.413 4.413 0 00-.485-5.666.944.944 0 00-1.334 0l-3.746 3.744c-.329.33-.76.494-1.191.494-.431 0-.863-.164-1.192-.494l-2.742-2.742a1.666 1.666 0 01-.492-1.19c0-.447.175-.876.492-1.193l3.746-3.744a.944.944 0 000-1.334A4.412 4.412 0 0039 3.713zm-15.682.55a2.831 2.831 0 00-.673.028L12.482 5.92a2.853 2.853 0 00-1.564.797l-.45.451-.366-.37a1.337 1.337 0 00-1.891 0L5.463 9.548a1.338 1.338 0 000 1.892l.367.368-5.312 5.312a1.337 1.337 0 000 1.89l6.511 6.512a1.337 1.337 0 001.89 0l4.259-4.257 5.258 5.256 6.75-6.75-5.256-5.256 6.355-6.358a1.337 1.337 0 000-1.89L25.11 5.09a2.852 2.852 0 00-1.79-.826zm10.5 24.141l-6.75 6.748 8.815 8.813a4.772 4.772 0 006.748-6.75l-8.813-8.81z',
  viewBox: '0 0 50 50',
  displayName: 'Wrench and Hammer Icon'
})

export default WrenchAndHammerIcon
