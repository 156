import { createIcon, Icon } from '@chakra-ui/react'

export const ICSIcon = createIcon({
  path: (
    <g>
      <path
        fill="url(#lin_grad)"
        fillRule="evenodd"
        d="M30.21 2.604a8.706 8.706 0 00-12.42 0l-7.575 7.708-7.673 8.242a8.706 8.706 0 00-.147 11.703l7.82 8.834 7.74 7.468a8.706 8.706 0 0012.09 0l7.74-7.468 7.82-8.834a8.706 8.706 0 00-.147-11.703l-7.673-8.242-7.575-7.708M24.33 29.59V19.152h-3.095l-4.02 5.776v-5.776h-3.13V29.59h3.149l3.983-5.731v5.731h3.113m7.242-10.438h-6.208V29.59h5.723c.238 0 .732-.053 1.483-.142.559-.072.98-.187 1.254-.348.449-.25.806-.588 1.062-1.025.257-.428.394-.918.394-1.453 0-.669-.183-1.221-.55-1.658-.365-.437-.924-.749-1.702-.927.513-.178.897-.401 1.163-.704.393-.437.595-.963.595-1.587a2.39 2.39 0 00-.833-1.845c-.559-.5-1.346-.75-2.381-.75m-1.428 4.093h-1.438v-1.98h1.465c.504 0 .852.08 1.053.259.21.17.311.401.311.713 0 .321-.1.57-.31.749-.202.17-.56.259-1.081.259m.247 4.1h-1.685v-2.113h1.676c.586 0 .989.09 1.227.277.229.187.338.437.338.748 0 .33-.11.589-.348.794-.238.196-.631.294-1.208.294m15.308-1.177l-2.911-.855c-.147.66-.376 1.167-.705 1.506-.32.348-.806.526-1.438.526-.65 0-1.162-.214-1.52-.642-.366-.428-.54-1.221-.54-2.38 0-.927.147-1.613.449-2.05.403-.588.98-.874 1.73-.874.33 0 .632.063.907.196.265.134.494.321.677.562.11.152.22.383.32.695l2.922-.633c-.376-1.096-.953-1.916-1.722-2.451-.769-.526-1.84-.793-3.186-.793-1.73 0-3.067.463-4.01 1.39-.943.927-1.41 2.255-1.41 3.976 0 1.293.265 2.362.806 3.191.53.83 1.171 1.409 1.904 1.739.742.33 1.694.49 2.866.49.961 0 1.748-.134 2.371-.401a4.114 4.114 0 001.566-1.204c.42-.535.723-1.194.924-1.988"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M40.683 18.973c-1.731 0-3.07.464-4.012 1.39-.943.928-1.408 2.255-1.408 3.975 0 1.293.264 2.363.804 3.192.531.829 1.172 1.408 1.905 1.738.741.33 1.693.49 2.865.49.961 0 1.75-.133 2.373-.4a4.11 4.11 0 001.564-1.203c.422-.535.725-1.195.926-1.988l-2.912-.856c-.146.66-.375 1.167-.705 1.506-.32.348-.806.527-1.438.527-.65 0-1.162-.214-1.52-.642-.365-.428-.54-1.222-.54-2.381 0-.927.147-1.612.45-2.049.402-.588.979-.875 1.73-.875.33 0 .631.064.906.197.265.134.494.32.678.56.11.152.22.384.32.696l2.92-.633c-.376-1.096-.952-1.916-1.72-2.45-.77-.527-1.84-.794-3.186-.794zm-26.598.178v10.44h3.148l3.985-5.733v5.732h3.111V19.151h-3.094l-4.02 5.777v-5.777zm11.28 0v10.44h5.722c.238 0 .731-.054 1.482-.143.559-.071.981-.187 1.256-.348.449-.25.804-.588 1.06-1.025.257-.428.395-.919.395-1.453 0-.669-.182-1.222-.549-1.659-.366-.436-.924-.749-1.703-.927.513-.178.897-.4 1.162-.703.394-.437.596-.964.596-1.588a2.39 2.39 0 00-.834-1.844c-.558-.5-1.346-.75-2.38-.75zm3.341 2.113h1.465c.503 0 .851.08 1.053.258.21.17.312.4.312.713 0 .32-.102.572-.312.75-.202.17-.559.258-1.08.258h-1.438zm0 3.967h1.676c.586 0 .988.088 1.226.275.23.188.34.438.34.75 0 .33-.111.588-.35.793-.237.196-.63.295-1.206.295h-1.686z"
      ></path>
      <linearGradient
        id="paint0_linear"
        x1="43.915"
        x2="0.829"
        y1="3.084"
        y2="43.602"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BF2F26"></stop>
        <stop offset="1" stopColor="#E45654"></stop>
      </linearGradient>
      <linearGradient
        id="lin_grad"
        x1="43.915"
        x2="0.829"
        y1="3.084"
        y2="43.602"
        gradientUnits="userSpaceOnUse"
        xlinkHref="#paint0_linear"
      ></linearGradient>
    </g>
  ),
  viewBox: '0 0 48 49',
  displayName: 'ICS Company Icon'
})

export const UralGeoIcon = createIcon({
  path: (
    <g>
      <path
        d="M19.901 48.65c-1.46-.287-2.78-.649-2.936-.804-.156-.156.413-.98 1.265-1.832 1.374-1.374 2.038-1.548 5.89-1.548 4.303 0 9.016-.974 9.016-1.863 0-.93-4.564-1.932-8.681-1.906-3.626.023-4.823.332-8.428 2.182-3.033 1.556-4.485 2.003-5.224 1.608-3.064-1.64-9.259-10.444-8.1-11.514.734-.68 7.631-2.286 13.2-3.076 5.694-.808 18.2-.341 23.743.886 2.084.461 3.889.667 4.01.458.123-.21.412-1.265.644-2.346l.422-1.965H.365l.433-3.78c.238-2.078.974-5.234 1.636-7.012 1.362-3.657.682-3.536 8.934-1.597 5.73 1.347 19.71 1.553 25.548.376 2.245-.452 4.08-.996 4.078-1.207-.001-.212-.614-1.15-1.361-2.083l-1.36-1.698-3.626 1.698c-3.217 1.505-4.311 1.698-9.674 1.698-5.567 0-6.358-.153-9.977-1.923-2.162-1.058-4.587-2.452-5.389-3.1L8.149 7.125l2.58-1.87C14.948 2.2 19.454.788 24.973.79c2.66 0 5.636.317 6.611.702l1.773.7-1.514 1.514c-1.343 1.344-2.087 1.531-6.61 1.667-2.804.084-5.914.512-6.911.951l-1.814.8 1.814.792c5.037 2.2 12.317 1.402 17.395-1.906 2.697-1.758 3.325-1.543 6.938 2.378 2.305 2.5 4.994 6.888 4.994 8.148 0 .571-6.356 2.19-12.396 3.155-5.545.887-16.209.699-22.819-.402-3.267-.544-6.036-.885-6.154-.756-.117.129-.403 1.118-.634 2.199l-.421 1.965h44.41l-.372 3.558c-.372 3.558-1.884 8.866-2.877 10.095-.371.46-1.793.33-4.816-.438-5.555-1.41-16.03-2.467-20.075-2.025-7.439.814-12.546 1.69-12.546 2.153 0 .274.627 1.226 1.392 2.116l1.393 1.619 2.387-1.2c8.033-4.04 18.301-3.074 25.505 2.402l1.954 1.485-1.35 1.259c-2.055 1.918-7.228 4.315-10.752 4.983-3.637.69-5.85.678-9.577-.054z"
        fill="#953128"
      />
    </g>
  ),
  viewBox: '0 0 50 50',
  displayName: 'Ural Geo Company Icon'
})

export const SelfEmployedIcon = createIcon({
  path: (
    <g>
      <path
        d="M.732 34.57l.08.448c.044.247.372 2.83.73 5.74.356 2.91.69 5.565.744 5.9l.095.611H27.748l-.012-.366c-.007-.201-.355-3.04-.774-6.308l-.76-5.942-12.735-.042L.732 34.57m13.008 4.21a2.135 2.135 0 110 4.27 2.135 2.135 0 010-4.27"
        fill="#707070"
      />
      <path
        d="M29.174 40.498c-.882-7.08-.98-7.515-1.793-7.885-.263-.12-3.088-.177-8.764-.177-4.785 0-8.385.04-8.385-.043 0-.31 1.475-2.205 2.56-3.225 1.932-1.815 3.997-2.862 6.364-3.227 1.116-.172 1.225-.16 1.954.208 4.905 2.486 9.407 2.599 14.145.354l1.564-.74 1.077.164c5.062.771 9.295 4.946 10.976 10.823.282.988.335 1.764.396 5.82l-.009 4.68h-9.583-9.66l-.842-6.752"
        fill="#5184be"
      />
      <circle cx="27.859" cy="13.597" r="10.866" fill="#e0ac69" />
    </g>
  ),
  viewBox: '0 0 50 50',
  displayName: 'Self Employed Icon'
})

export const InlineTelecomSolutionsIcon = createIcon({
  path: (
    <g>
      <path
        d="m19.81 15.621 13 13.09 12.999-13.09 13.09-12.999H47.914L32.81 17.727 17.705 2.622H6.72ZM2.602 12.234v5.493L17.705 32.83 2.601 47.935V58.92L15.6 45.83 28.69 32.83 15.6 19.832 2.601 6.742Z"
        fill="#969796"
      />
      <path
        d="M58.897 11.29 47.912 22.09v26.302H22.648L11.845 59.378h47.053V36.035l-.002-1.22v-.173z"
        fill="#d81146"
      />
    </g>
  ),
  viewBox: '0 0 61.5 62',
  displayName: 'Inline Telecom Solutions Icon'
})

export const RealisteIcon = createIcon({
  path: (
    <g>
      <defs>
        <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="18.612" y1="629.68" x2="220.87"
          y2="57.609" gradientTransform="matrix(.07087 0 0 .07087 14.058 .025)">
          <stop offset=".049" stopColor="#00acff" />
          <stop offset=".21" stopColor="#2193f3" />
          <stop offset=".384" stopColor="#556bdf" />
          <stop offset=".588" stopColor="#7b4ed1" />
          <stop offset=".767" stopColor="#923cc8" />
          <stop offset=".938" stopColor="#9b35c5" />
        </linearGradient>
      </defs>
      <path
        d="M18.023 0h17.32a4.082 4.082 0 014.083 4.078v35.25a4.083 4.083 0 01-4.082 4.082h-17.32a4.083 4.083 0 01-4.083-4.082V4.078A4.082 4.082 0 0118.023 0zm0 0"
        fill="#fff" />
      <path
        d="M12.246.031C5.461.06 0 5.492 0 12.277v25.45c0 6.785 5.46 12.25 12.246 12.25h25.488a12.222 12.222 0 0012.246-12.25v-25.45C49.98 5.492 44.516.113 37.734.031L35.391 0c-.547.066-.832.555-1.075.898l-9.73 13.774a.558.558 0 00.457.875h7.289c.45 0 .711.5.453.871L15.883 40.387c-.246.343-.785.074-.649-.328l6.54-20.02a.55.55 0 00-.524-.723h-6.64a.554.554 0 01-.524-.722L19.852.91a.754.754 0 00-.606-.91zm0 0"
        fill="url(#a)" />
    </g>
  ),
  viewBox: '0 0 50 50',
  displayName: 'Realiste Company Icon'
})

/** Icon factory */
export const getIcon = ({ name, props }) => {
  switch (name) {
    case 'company-ics':
      return <ICSIcon {...props} />
    case 'company-uralgeo':
      return <UralGeoIcon {...props} />
    case 'company-self-employed':
      return <SelfEmployedIcon {...props} />
    case 'inline-telecom-solutions':
      return <InlineTelecomSolutionsIcon {...props} />
    case 'realiste':
      return <RealisteIcon {...props} />
    default:
      return <Icon {...props} />
  }
}

/** Logo icon */
const SuitCaseIcon = createIcon({
  d: 'M26.785 26.7h-3.57a.202.202 0 00-.203.205v7.523c0 .115.089.206.203.206h3.566a.203.203 0 00.204-.206v-7.523a.2.2 0 00-.2-.205zM3.592 10.73h12.844V7.18c0-1.07.873-1.943 1.941-1.943h13.246c1.068 0 1.941.874 1.941 1.943v3.55h12.844a2.761 2.761 0 012.743 2.743v6.628c-3.101 2.126-6.3 3.938-9.6 5.416a54.09 54.09 0 01-10.275 3.433V26.3c0-1.224-.985-2.21-2.208-2.21h-4.14a2.205 2.205 0 00-2.209 2.21v2.59a53.837 53.837 0 01-10.016-3.373C7.315 24 4.032 22.125.85 19.923v-6.45a2.76 2.76 0 012.743-2.743zm45.56 13.302v17.987c0 .728-.292 1.424-.81 1.935-.5.497-1.184.809-1.934.809H3.592a2.711 2.711 0 01-1.933-.809c-.5-.5-.81-1.184-.81-1.935V23.867a63.898 63.898 0 008.36 4.539c3.7 1.66 7.537 2.929 11.513 3.778v2.85c0 1.223.987 2.21 2.21 2.21h4.14a2.206 2.206 0 002.209-2.21v-2.798a57.57 57.57 0 0011.77-3.833 63.84 63.84 0 008.1-4.371zm-29.29-16.15a.255.255 0 00-.246.249v2.523H30.38V8.13a.255.255 0 00-.246-.248z',
  viewBox: '0 0 50 50',
  displayName: 'Suit Case Icon'
})

export default SuitCaseIcon
