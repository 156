import { createIcon } from '@chakra-ui/react'

export const CertificateIcon = createIcon({
  path: (
    <g>
      <path
        d="M461,88.31v400H61v-210v-190h33.52c-0.03,0.02-0.06,0.04-0.09,0.06h355.24 c0.65,0,1.3-0.02,1.95-0.06H461z M331,148.31v-20h-20v20H331z M291,148.31v-20h-20v20H291z M251,148.31v-20h-20v20H251z M211,148.31v-20h-20v20H211z"
        fill="#FFF2D4"
      />
      <g>
        <g>
          <g>
            <path
              d="M72.75,289.56v-190h33.52c-0.03,0.02-0.06,0.04-0.09,0.06H461V88.31h-9.38 c-0.65,0.04-1.3,0.06-1.95,0.06H94.43c0.03-0.02,0.06-0.04,0.09-0.06H61v190v210h11.75V289.56z"
              fill="#E5D0A8"
            />
          </g>
        </g>
      </g>
      <path
        d="M141,408.31c11.26,0,21.64-3.72,30-10v60l-30-20l-30,20v-60 C119.36,404.59,129.74,408.31,141,408.31z"
        fill="#D65B5B"
      />
      <path
        d="M114.5,406.977c8.36,6.28,18.74,10,30,10c9.738,0,18.815-2.787,26.5-7.602V398.31 c-8.36,6.28-18.74,10-30,10s-21.64-3.72-30-10v60l3.5-2.333V406.977z"
        fill="#C45353"
      />
      <path
        d="M191,358.31c0,16.36-7.86,30.881-20,40c-8.36,6.28-18.74,10-30,10s-21.64-3.72-30-10 c-12.14-9.119-20-23.64-20-40c0-27.609,22.39-50,50-50S191,330.7,191,358.31z"
        fill="#FF7979"
      />
      <path
        d="M98.667,373.643c0-27.609,22.39-50,50-50c16.498,0,31.126,7.998,40.233,20.322 c-6.168-20.618-25.277-35.655-47.9-35.655c-27.61,0-50,22.391-50,50c0,11.11,3.633,21.367,9.762,29.665 C99.404,383.434,98.667,378.626,98.667,373.643z"
        fill="#D65B5B"
      />
      <path
        d="M95.35,25.25c10.14,6.91,16.59,18.43,16.59,31.33c0,13.32-6.88,25.27-17.42,31.73H61v190H31v-210 c0-26.26,20.12-46.75,46.38-44.44L95.35,25.25z"
        fill="#E2BF7F"
      />
      <path
        d="M38.75,78.06c0-26.26,20.12-46.75,46.38-44.44L103.1,35c1.449,0.987,2.813,2.079,4.103,3.245 c-2.869-5.173-6.918-9.633-11.853-12.995l-17.97-1.38C51.12,21.56,31,42.05,31,68.31v210h7.75V78.06z"
        fill="#D1AD74"
      />
      <path
        d="M471.9,34.58c5.64,5.76,9.1,13.7,9.1,22.46c0,16.41-12.8,30.26-29.38,31.27H94.52 c10.54-6.46,17.42-18.41,17.42-31.73c0-12.9-6.45-24.42-16.59-31.33h354.32C458.42,25.25,466.26,28.82,471.9,34.58z"
        fill="#F7D794"
      />
      <g>
        <g>
          <g>
            <path
              d="M94.52,88.31h8.844c3.596-5.394,5.836-11.802,6.251-18.656 C106.761,77.458,101.437,84.071,94.52,88.31z"
              fill="#E2BF7F"
            />
          </g>
          <g>
            <path
              d="M105.849,36H447.42c8.75,0,16.59,3.57,22.23,9.33c5.64,5.76,9.1,13.7,9.1,22.46 c0,0.318-0.014,0.633-0.023,0.95c1.468-3.631,2.273-7.584,2.273-11.7c0-8.76-3.46-16.7-9.1-22.46 c-5.641-5.76-13.48-9.33-22.23-9.33H95.35C99.554,28.115,103.11,31.781,105.849,36z"
              fill="#E2BF7F"
            />
          </g>
        </g>
      </g>
      <rect height="20" width="20" x="311" y="128.31" />
      <rect height="20" width="20" x="271" y="128.31" />
      <rect height="20" width="20" x="231" y="128.31" />
      <rect height="20" width="20" x="191" y="128.31" />
      <path d="M461,493.31H61c-2.761,0-5-2.238-5-5v-400c0-2.761,2.239-5,5-5h400c2.762,0,5,2.239,5,5v400 C466,491.071,463.762,493.31,461,493.31z M66,483.31h390v-390H66V483.31z" />
      <path d="M449.67,93.37H94.43c-2.761,0-5-2.239-5-5s2.239-5,5-5h355.24c0.548,0,1.095-0.017,1.643-0.051 C465.157,82.476,476,70.933,476,57.04c0-7.175-2.725-13.909-7.672-18.962c-4.943-5.048-11.569-7.828-18.658-7.828H95.35 c-2.761,0-5-2.239-5-5s2.239-5,5-5h354.32c9.8,0,18.963,3.847,25.803,10.832C482.262,38.015,486,47.234,486,57.04 c0,19.169-14.968,35.097-34.076,36.261C451.178,93.347,450.419,93.37,449.67,93.37z" />
      <path d="M61,283.31H31c-2.761,0-5-2.238-5-5v-210c0-14.581,5.807-28.262,15.932-37.535c9.663-8.85,22.406-13.072,35.886-11.886 l17.915,1.375c0.872,0.067,1.71,0.361,2.433,0.854c11.755,8.011,18.774,21.268,18.774,35.462c0,2.761-2.239,5-5,5s-5-2.239-5-5 c0-10.453-4.968-20.246-13.353-26.451l-16.59-1.274c-10.706-0.939-20.741,2.361-28.312,9.294C40.624,45.534,36,56.527,36,68.31 v205h25c2.761,0,5,2.238,5,5S63.761,283.31,61,283.31z" />
      <path d="M94.483,93.32c-1.613,0-3.198-0.763-4.158-2.191c-1.54-2.292-0.976-5.369,1.316-6.909 c0.058-0.039,0.207-0.137,0.266-0.173c9.272-5.683,15.033-16.208,15.033-27.467c0-2.761,2.239-5,5-5s5,2.239,5,5 c0,14.704-7.581,28.488-19.785,35.979C96.329,93.073,95.401,93.32,94.483,93.32z" />
      <path d="M391,193.31H121c-2.761,0-5-2.239-5-5s2.239-5,5-5h270c2.762,0,5,2.239,5,5S393.762,193.31,391,193.31z" />
      <path d="M391,233.31H121c-2.761,0-5-2.239-5-5s2.239-5,5-5h270c2.762,0,5,2.239,5,5S393.762,233.31,391,233.31z" />
      <path d="M391,273.31H201c-2.761,0-5-2.238-5-5s2.239-5,5-5h190c2.762,0,5,2.238,5,5S393.762,273.31,391,273.31z" />
      <path d="M391,313.31H211c-2.761,0-5-2.238-5-5s2.239-5,5-5h180c2.762,0,5,2.238,5,5S393.762,313.31,391,313.31z" />
      <path d="M391,353.31H231c-2.761,0-5-2.238-5-5s2.239-5,5-5h160c2.762,0,5,2.238,5,5S393.762,353.31,391,353.31z" />
      <path d="M171,463.31c-0.97,0-1.938-0.282-2.774-0.84L141,444.318l-27.227,18.151c-1.534,1.022-3.507,1.118-5.133,0.248 c-1.625-0.87-2.641-2.564-2.641-4.408v-60c0-2.762,2.239-5,5-5s5,2.238,5,5v50.657l22.227-14.817c1.68-1.119,3.867-1.119,5.547,0 L166,448.967V398.31c0-2.762,2.239-5,5-5s5,2.238,5,5v60c0,1.844-1.015,3.538-2.641,4.408 C172.62,463.113,171.809,463.31,171,463.31z" />
      <path d="M141,413.31c-12.01,0-23.422-3.805-33.003-11.002C94.018,391.807,86,375.771,86,358.31c0-30.327,24.673-55,55-55 s55,24.673,55,55c0,17.461-8.018,33.497-21.997,43.998C164.422,409.505,153.01,413.31,141,413.31z M141,313.31 c-24.813,0-45,20.187-45,45c0,14.286,6.562,27.408,18.003,36.002c7.835,5.887,17.171,8.998,26.997,8.998 s19.162-3.111,26.997-8.998C179.438,385.718,186,372.596,186,358.31C186,333.496,165.813,313.31,141,313.31z" />
    </g>
  ),
  viewBox: '0 0 512 512',
  displayName: 'Certificate Icon'
})

/** Logo icon */
const StarIcon = createIcon({
  d: 'M24.993 1.526c-.079 0-.155.06-.249.181-.092.107-1.535 3.634-3.218 7.84-1.683 4.21-3.194 7.775-3.353 7.949-.148.16-.44.361-.628.44-.294.121-15.162 1.217-16.445 1.217-.493 0-.88.292-.732.56.065.12 2.978 2.592 6.464 5.505 5.637 4.702 6.373 5.356 6.613 5.904l.266.613-2.041 8.175c-1.122 4.487-2.046 8.241-2.033 8.335.014.401.467.282 1.563-.413a1279.343 1279.343 0 007.16-4.487c5.702-3.567 6.025-3.755 6.639-3.755.615 0 .935.188 6.639 3.755a1252.585 1252.585 0 007.159 4.487c1.123.707 1.564.815 1.564.374 0-.108-.896-3.808-2.005-8.214-1.095-4.409-2.002-8.151-2.002-8.31.013-.721.708-1.39 6.88-6.532 3.46-2.87 6.331-5.332 6.398-5.437.148-.268-.241-.56-.735-.56-1.283 0-16.151-1.097-16.445-1.219a2.175 2.175 0 01-.628-.438c-.16-.16-1.67-3.741-3.353-7.962-1.682-4.209-3.137-7.734-3.218-7.827-.1-.12-.181-.181-.26-.181z',
  viewBox: '0 0 50 50',
  displayName: 'Star Icon'
})

export default StarIcon
