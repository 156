import { createIcon } from '@chakra-ui/react'

export const SunIcon = createIcon({
  d: 'M50 81.624c-17.616 0-31.54-13.924-31.54-31.54S32.383 18.544 50 18.544s31.54 13.924 31.54 31.54S67.617 81.624 50 81.624M50 14.014c-2.684 0-4.53-1.846-4.53-4.53v-4.53c0-2.684 1.846-4.53 4.53-4.53s4.53 1.846 4.53 4.53v4.53c0 2.684-1.846 4.53-4.53 4.53M50 99.576c-2.684 0-4.53-1.846-4.53-4.53v-4.53c0-2.684 1.846-4.53 4.53-4.53s4.53 1.846 4.53 4.53v4.53c0 2.684-1.846 4.53-4.53 4.53M95.13 54.614H90.6c-2.684 0-4.53-1.846-4.53-4.53s1.846-4.53 4.53-4.53h4.53c2.684 0 4.53 1.846 4.53 4.53s-1.846 4.53-4.53 4.53M9.4 54.614H4.87c-2.684 0-4.53-1.846-4.53-4.53s1.846-4.53 4.53-4.53H9.4c2.684 0 4.53 1.846 4.53 4.53s-1.846 4.53-4.53 4.53M81.54 22.905c-2.684 0-4.53-1.845-4.53-4.53 0-1.342.504-2.18 1.343-3.187l4.53-4.53c1.845-1.845 4.53-1.845 6.375 0 1.845 1.846 1.845 4.53 0 6.375l-4.53 4.53c-1.006 1.007-1.845 1.342-3.187 1.342M18.46 22.905c-1.343 0-2.182-.503-3.188-1.342l-4.53-4.53c-1.845-1.845-1.845-4.53 0-6.375 1.846-1.845 4.53-1.845 6.375 0l4.53 4.53c1.846 1.845 1.846 4.53 0 6.375-1.007 1.007-1.845 1.342-3.188 1.342M86.07 90.516c-1.342 0-2.18-.503-3.187-1.342l-4.53-4.53c-1.846-1.845-1.846-4.53 0-6.375 1.845-1.845 4.53-1.845 6.375 0l4.53 4.53c1.845 1.845 1.845 4.53 0 6.375-1.007 1.007-1.846 1.342-3.188 1.342M13.93 90.516c-2.685 0-4.53-1.845-4.53-4.53 0-1.342.503-2.18 1.342-3.187l4.53-4.53c1.845-1.845 4.53-1.845 6.375 0 1.846 1.846 1.846 4.53 0 6.375l-4.53 4.53c-.839 1.007-1.845 1.342-3.187 1.342',
  viewBox: '0 0 100 100',
  displayName: 'Sun Icon'
})

export const MoonIcon = createIcon({
  d: 'M67.643 4.75C60.739 1.845 53.109.39 45.479.937 65.281 11.109 73.093 35.27 62.92 54.892c-10.173 19.62-34.335 27.432-53.956 17.44-2.18-1.09-4.36-2.544-6.358-3.997 10.174 25.252 38.877 37.424 63.948 27.25 25.252-10.173 37.424-38.877 27.25-64.129-4.723-11.99-14.17-21.619-26.16-26.705z',
  viewBox: '0 0 100 100',
  displayName: 'Moon Icon'
})

export const ChevronDownIcon = createIcon({
  d: 'M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z',
  viewBox: '0 0 24 24',
  displayName: 'Chevron Down Icon'
})

export const HamburgerIcon = createIcon({
  d: 'M 3 5 A 1.0001 1.0001 0 1 0 3 7 L 21 7 A 1.0001 1.0001 0 1 0 21 5 L 3 5 z M 3 11 A 1.0001 1.0001 0 1 0 3 13 L 21 13 A 1.0001 1.0001 0 1 0 21 11 L 3 11 z M 3 17 A 1.0001 1.0001 0 1 0 3 19 L 21 19 A 1.0001 1.0001 0 1 0 21 17 L 3 17 z',
  viewBox: '0 0 24 24',
  displayName: 'Hamburger Icon'
})

export const CloseIcon = createIcon({
  d: 'M.439,21.44a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,1,0,2.122-2.121L14.3,12.177a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.44L12.177,9.7a.25.25,0,0,1-.354,0L2.561.44A1.5,1.5,0,0,0,.439,2.561L9.7,11.823a.25.25,0,0,1,0,.354Z',
  viewBox: '0 0 24 24',
  displayName: 'CloseIcon'
})
