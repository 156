import { createIcon } from '@chakra-ui/react'

/** Logo icon */
const FlaskIcon = createIcon({
  d: 'M19.642.189c-.525 0-.993.175-1.285.233-1.635.584-2.86 2.219-2.86 4.087a4.238 4.238 0 002.568 3.911v14.129c-4.12 5.288-8.313 10.52-12.493 15.762-1.693 2.16-2.043 5.02-.817 7.472a7.032 7.032 0 006.363 4.028h27.848a6.912 6.912 0 006.304-4.028c1.167-2.452.876-5.312-.817-7.472A4387.085 4387.085 0 0131.96 22.607V8.478c1.518-.642 2.569-2.218 2.569-3.969a4.31 4.31 0 00-4.32-4.32H19.642zm.175 2.919h10.392c.759 0 1.4.642 1.4 1.46 0 .7-.525 1.284-1.226 1.342l-1.284.117v17.397c1.449 1.922 2.89 3.705 4.452 5.663H16.489c1.488-1.835 3.091-3.915 4.437-5.663V6.027l-1.284-.117c-.7-.058-1.227-.642-1.227-1.401s.643-1.401 1.402-1.401z',
  viewBox: '0 0 50 50',
  displayName: 'Flask Icon'
})

export default FlaskIcon
